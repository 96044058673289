<template>
  <div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-6 my-auto">
          <h3
            data-aos-duration="2000"
            data-aos="fade-right"
            class="mb-4 header-spacing moving-gradient-text fw-bolder"
          >
            STARTUPS
          </h3>
          <p class="mb-4">
            We have a range of products and services specifically for startups!
            Our web packages are designed to fit the startup budget whilst
            providing a modern, fluid, lightning fast user experience.
          </p>
          <p class="mb-0">
            Friday Collective has a bespoke content management system giving you
            the power to manage your website, forms, ecommerce products, blog
            and marketing.
          </p>
        </div>
        <div class="col-8 mx-auto col-md-6 p-4 my-auto">
          <img
            src="@/assets/images/pages/startup.png"
            alt="Startups - Friday Collective"
            width="100%"
          />
        </div>
      </div>
    </div>

    <div class="container-fluid moving-gradient my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="row py-5">
              <div class="col-12 col-md my-auto">
                <h1 class="text-white">WE'VE BEEN THERE...</h1>
                <p class="text-white">
                  Let us share our startup experience with you
                </p>
              </div>
              <div class="col-12 col-md-auto my-auto">
                <router-link
                  class="btn btn-light btn-lg shadow"
                  to="/send-smoke-signal"
                >
                  <i class="far fa-rocket me-2"></i>Beam me up!
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="container">
      <div class="row mb-4 text-center">
        <div class="col">
          <h2 class="fw-bolder header-spacing moving-gradient-text mb-5">
            TURBO CHARGED WITH MODSNIP CRM
          </h2>
        </div>

        <div class="row text-center">
          <div class="col-md-6 mx-auto">
            <div class="card border-0 bg-light shadow-sm">
              <div class="card-body">
                <p class="fw-bold mb-0">
                  Packages to suit all types of websites for all types of
                  startups!
                </p>
                <p>
                  Bespoke websites with an modular cms / crm for all your
                  startup needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->

      <div class="row text-center">
        <div class="col-md-4 mb-4 p-5">
          <div class="card border-0 bg-light shadow-sm">
            <div class="card-body">
              <h2 class="moving-gradient-text fw-bolder">MONDAY</h2>
              <i class="far fa-rocket fa-5x my-4 text-pink"></i>
              <ul class="list-unstyled mt-4 fw-bold">
                <li>Up to 5 pages</li>
                <li>Contact / Enquiry Form</li>
                <li>Blogging</li>
                <li>CRM Login</li>
              </ul>

              <button class="btn btn-light moving-gradient-button btn-lg mt-5">
                Learn more
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-4 mb-4 p-5">
          <div class="card border-0 moving-gradient shadow-lg">
            <div class="card-body">
              <h2 class="text-white fw-bolder">WEDNESDAY</h2>
              <i class="far fa-rocket fa-5x my-4 text-white"></i>
              <ul class="list-unstyled mt-4 fw-bold text-white">
                <li>Up to 10 pages</li>
                <li>Contact / Enquiry Form</li>
                <li>2 Custom forms</li>
                <li>Blogging</li>
                <li>CRM Login</li>
                <li>Marketing</li>
                <li>Analytics</li>
              </ul>

              <button class="btn btn-light btn-lg mt-5">Learn more</button>
            </div>
          </div>
        </div>

        <div class="col-md-4 mb-4 p-5">
          <div class="card border-0 bg-light shadow-sm">
            <div class="card-body">
              <h2 class="moving-gradient-text fw-bolder">FRIDAY</h2>
              <i class="far fa-rocket fa-5x my-4 text-pink"></i>
              <ul class="list-unstyled mt-4 fw-bold">
                <li>Up to 15 pages</li>
                <li>Contact / Enquiry Form</li>
                <li>Unlimited custom forms</li>
                <li>Blogging</li>
                <li>CRM Login</li>
                <li>Marketing</li>
                <li>Analytics</li>
              </ul>

              <button class="btn btn-light moving-gradient-button btn-lg mt-5">
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
